import { useState, useEffect, Fragment } from 'react';
import bleatLogoWhite from '../../Assets/Images/logo-bleat-new.png';
import bleatLogoBlack from '../../Assets/Images/logo-bleat-desktop.png';
import bleatMobileBlack from '../../Assets/Images/bleat-logo-mobile.png';
import bleatMobileWhite from '../../Assets/Images/logo-bleat-mobile-white.png';
import bleatMobDark from '../../Assets/Images/bleatlogoMobDark.svg';
import useWindowDimensions from '../../Hooks/useWindowDimensions';
import { useLocation } from "react-router-dom";
import { Link } from 'react-router-dom';


const Footer = props => {
  
  const { windowWidth }         = useWindowDimensions();
  const currentLocation         = useLocation();
  const currentPath             = currentLocation.pathname.split("/")[1];
  const [logo, setLogo]         = useState(bleatMobileBlack);
  const [isBgDark, setIsBgDark] = useState(false);

  // console.log(currentLocation);
  // console.log(currentLocation.pathname.split("/")[1]);

  useEffect(() => {

    // paths with dark background
    // const pathsWithDarkBg = ["results", "invite-team", "invite-team-thanks", "dashboard"];
    const pathsWithDarkBg = [];

    if(pathsWithDarkBg.includes(currentPath)) {
      // setLogo(bleatMobileWhite);
      setIsBgDark(true);
    } else {
      // setLogo(bleatMobileBlack);
      setIsBgDark(false);
    }

  }, [currentLocation, currentPath]);

  useEffect(() => {
    if(isBgDark) {
      if(windowWidth > 830) setLogo(bleatLogoWhite);
      else setLogo(bleatMobileWhite);
    }
    else {
      if(windowWidth > 830) setLogo(bleatLogoBlack);
      else setLogo(bleatMobileBlack);
    }
  }, [isBgDark, windowWidth]);


  return <Fragment>

    <footer style={{ minHeight: '70px', marginTop:"25px" }}>

      {/* <div className={'bleat-logo'}>
        {windowWidth > 830 ? <Link to="/start" className="logo-right" style={{}}><img src={logo} width="auto" height={67.5} alt="Bleat" /></Link> : <Link to="/" className="logo-right"></Link>}
      </div> */}

      {windowWidth <= 830 &&
        <div className="bleat-logo-mobile">
          <img src={bleatMobDark} alt="bleat logo" height="43px" width="auto" />
        </div>}

      {/* <div className="bleat-logo-mobile">
        <img src={bleatLogoMobile} alt="bleat logo" height="43px" width="auto" />
      </div> */}

    </footer>
  </Fragment>
}

export default Footer;
