import { Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import useWindowDimensions from "../Hooks/useWindowDimensions";


const SendInvite = () => {
  
  const navigate  = useNavigate();
  const { id }    = useParams();
  const { windowWidth } = useWindowDimensions()

  const sendInviteHandler = () => {
    navigate("/invite-team/" + id , { replace: true });
  }


  return (
    <div className="send-invite-container">

      <div className="send-invite-header">
        <h1>Invite others to journey with you!</h1>
      </div>
      
      <div className="send-invite-paragraph">
        {
          windowWidth > 830 ? <>
            <p className="send-invite-line-1">We're praying this assessment becomes a helpful tool to maximize Kingdom impact! Invite your friends, co-workers, and </p>
            <p className="send-invite-line-2">fellow leaders to take this assessment and evaluate how we can journey together toward becoming praying leaders!</p>
          </>
          :
          <p>We're praying this assessment becomes a helpful tool to maximize Kingdom impact! Invite friends, co-workers, and fellow leaders to take this assessment and evaluate how we can journey together toward becoming praying leaders!</p>
        }
      </div>

      <div className="send-invite-btn-container">
        <Link to={"/invite-team/" + id} target="_blank">
          <Button className="cta">SEND INVITE</Button>
        </Link>
      </div>
    </div>
  )
}

export default SendInvite;

