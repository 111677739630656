import { useState, useEffect, useContext } from 'react';
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import { Box, Stepper, Button, CircularProgress, LinearProgress } from '@mui/material';

import Question from '../Components/Questions';
import ProgressBar from '../Components/ProgressBar';
import ProgressBarSingle from '../Components/ProgressBarSingle';

import prevArrow from '../Assets/Images/prev-arrow.svg';
import nextArrow from '../Assets/Images/next-arrow.svg';
import nextArrowWhite from '../Assets/Images/next-arrow-white.svg';
import useWindowDimensions from '../Hooks/useWindowDimensions';

// import AssessmentProgressContext from '../Store/AssessmentProgressContext';
import AssessmentQuestionContext from '../Store/AssessmentQuestionContext';
import QuestionsProgressContext from '../Store/QuestionsProgressContext';


const AssessmentV3 = () => {

  // const assessProgressCtx = useContext(AssessmentProgressContext);
  // console.log("assessProgressCtx", assessProgressCtx);
  const assessCtx       = useContext(AssessmentQuestionContext);
  const questionsCtx    = useContext(QuestionsProgressContext);
  const { windowWidth } = useWindowDimensions();
  let navigate          = useNavigate();
  const { id, pageId }  = useParams();
  
  console.log("pageId", pageId);
  
  const [searchParams, setSearchParams]     = useSearchParams();
  const [isLoading, setIsLoading]           = useState(true);
  const [activeStep, setActiveStep]         = useState(0);
  const [prevValue, setPrevValue]           = useState(0);
  // const [nextValue, setNextValue]           = useState(0);
  const [sections, setSections]             = useState([]);
  const [questions, setQuestions]           = useState([]);
  const [questionCount, setQuestionCount]   = useState(0);
  const [currentHash, setCurrentHash]       = useState(0);
  const [hasAdditional, setHasAdditional]   = useState(false);
  const [apiData, setApiData]               = useState();
  // const [oldQuestions, setOldQuestions]     = useState();
  
  // const [nextDisabled, setNextDisabled] = useState(true);
  // console.log("searchParams.get('member')", searchParams.get("member"));

  useEffect(() => {
    // document.body.style.backgroundColor = "#E5E5E5";
    
    setIsLoading(true);
    getQuestions();
    localStorage.removeItem("backFromDetailsPage");
    // if(id != null) {      
    //   getAssessment();
    // } else {
    //   getQuestions();
    // }
    
    // getOldQuestions();

    // assessProgressCtx.updateMainSectionsStatus(assessProgressCtx?.mainSections?.map(sec => {
    //   if(sec.title === "Response") return { ...sec, status: "active" };
    //   else return { ...sec, status: "unfinished" };
    // }));

  }, [pageId]);


  // useEffect(() => {
  //   getQuestions();
  // }, [pageId]);
  
  useEffect(() => {
    if(localStorage.getItem("backFromAddQPage") === "true" && questions?.length) {
      // console.log("activeStep", activeStep); console.log("questions", questions);
      setActiveStep(questions?.length - 1);
      localStorage.removeItem("backFromAddQPage");
    }
  }, [questions, pageId]);

  useEffect(()=>{
    setQuestionCount((questions?.length * sections.length));
  },[questions,sections])
  
  const getOldQuestions = () => {
    
    let clientId              = process.env.REACT_APP_CLIENT_ID;  
    fetch(process.env.REACT_APP_API_URI + '/v1/questions/' + clientId, {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' }
    }).then(response => {
      return response.json();
    }).then(data => {
      if(data.status === "success") {
        // let questions = data.questions;
        // if(retrieveStoredAnswers()) {
        //   let answeredQuestions = [];
        //   let storedQuestions = retrieveStoredAnswers();
        //   questions.forEach(q => {
        //     let question = storedQuestions.find(obj => obj.id == q.id);
        //     if(question) {
        //       q.answerTxt = question.answerTxt;
        //       q.answer = question.answer;
        //       q.answers = question.answers;
        //     }
        //   })
        // }
        console.log(data);
        setSections(data.sections);
        // setQuestions(data.questions);
        setQuestionCount(data.questions.length);
        setHasAdditional(data.hasAdditional);
        // assessCtx.addQuestions(questions);
        setIsLoading(false);
      } else {
        // setOpenSnackbar(true);
      }
    }).catch((err) => console.error("Failed to load old questions...", err));
  }

  function getAssessment() {
    // fetch(process.env.REACT_APP_API_URI + '/v1/assessment/' + id + '/review', {
		// 	method: 'GET',
		// 	mode: 'cors',
		// 	headers: { 'Content-Type': 'application/json' }
		// }).then(response => {
		// 	return response.json();
		// }).then(data => {
		// 	if(data.status == "success") {
		// 		setSections(data.sections);
		// 		setQuestions(data.questions);
    //     setQuestionCount(data.questions?.length);
    //     if((data.hasAdditional === "yes") || (data.hasAdditional === true)) setHasAdditional(true);
    //     setIsLoading(false);
    //     setActiveStep(data.questions?.length - 1);
    //     setPrevValue(data.questions?.[data.questions?.length - 1].answer);
    //     setCurrentHash(id);
		// 	} else {
		// 		// setOpenSnackbar(true);
		// 	}
		// });
  }

  function getQuestions() {

    // if(assessCtx.questions?.length > 0) {
    //   console.log("assessCtx.questions", assessCtx.questions);
    //   setQuestions(assessCtx.questions);
    //   setQuestionCount(assessCtx.questions?.length);
    //   setIsLoading(false);

    //   // go to the last additional question if coming back from Details page 
    //   // typeof localStorage.getItem("backFromDetailsPage") is string so "true"
    //   if(localStorage.getItem("backFromDetailsPage") === "true") {
    //     setActiveStep(assessCtx.questions?.length - 1);
    //   } else {
    //     // go to the first unanswered question 
    //     for (let i = 0; i < assessCtx.questions?.length; i++) {
    //       if(!assessCtx.questions?.[i]?.answer && !assessCtx.questions?.[i]?.answerTxt) {
    //         setActiveStep(i);
    //         break;
    //       } else setActiveStep(assessCtx.questions?.length - 1);
    //     }
    //   }
    // }
    // else {

      let clientId              = process.env.REACT_APP_CLIENT_ID;
      fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment/' + id + '/pages/' + pageId, {
        method: 'GET',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' }
      }).then(response => {
        return response.json();
      }).then(data => {
        if(data.status == "success") {
          console.log("getQuestions data", data);
          // let questions = data.questions;
          // if(retrieveStoredAnswers()) {
          //   let answeredQuestions = [];
          //   let storedQuestions = retrieveStoredAnswers();
          //   questions?.forEach(q => {
          //     let question = storedQuestions.find(obj => obj.id == q.id);
          //     if(question) {
          //       q.answerTxt = question.answerTxt;
          //       q.answer = question.answer;
          //       q.answers = question.answers;
          //     }
          //   })
          // }
          // setSections(data.sections);
          // setQuestions(data.questions);
          // setQuestionCount(data.questions?.length);
          // assessCtx.addQuestions(questions);
          // if((data.hasAdditional === "yes") || (data.hasAdditional === true)) setHasAdditional(true);

          // go to first unanswered question 
          let goingForward = localStorage.getItem("goingForward") === "true" ? true : false;

          const jumpToUnansweredQuestion = () => {
            let sortedQuestions = data?.data.questions;
            sortedQuestions = sortedQuestions?.sort((a, b) => a.orderNo - b.orderNo);
            for (let i = 0; i < sortedQuestions?.length; i++) {
              if(!sortedQuestions?.[i]?.answer) {
                setActiveStep(i);
                break;
              } else setActiveStep(sortedQuestions?.length - 1);
            }
          }

          if(goingForward) {
            setActiveStep(0);
            localStorage.removeItem("goingForward");
          }
          else jumpToUnansweredQuestion();

          setSections(data?.data.sections);
          setQuestionCount((data?.data?.questions?.length));
          setQuestions(data?.data?.questions);
          setApiData(data.data);
          setIsLoading(false);
        } else {
          // setOpenSnackbar(true);
        }
      }).catch((err) => console.error("Error while fetching questions...", err));
    // }
  }

  // console.log("searchParams", [...searchParams]); console.log("apiData", apiData);

  const currentSectionName = sections?.find(section => section.id === questions?.[activeStep]?.sectionId)?.section;

  console.log("hasAdditional", hasAdditional);


  const retrieveStoredAnswers = () => {
    if (typeof window !== 'undefined') {
      // Get the current localstorage
      let savedAnswers = JSON.parse(localStorage.getItem('assessmentAnswers'));
      if(savedAnswers && savedAnswers.length != 0) {
        return savedAnswers;
      }
    }
    return;
  }

  const prevQuestion = () => {
    // if(activeStep < 1) {
    //   // if(searchParams.get("member")) {
    //   if([...searchParams]?.length) {
    //     // navigate("/start?member=" + searchParams.get("member"), { replace: true });
    //     navigate("/start?" + searchParams, { replace: true });
    //   } else navigate("/start", { replace: true });
    // } else {
    //   setActiveStep(activeStep - 1);
    //   setPrevValue(questions?.[activeStep - 1].answer);
    // }
    if(activeStep > 0) {
      setActiveStep(activeStep - 1);
      // setPrevValue(questions?.[activeStep + 1].answer);
    } else {
      // navigateBackwardFunc();
      // navigate("/?" + searchParams, { replace: true });
      navigateBackToIntroPages();
    }
  };

  const nextQuestion = () => {
    // // if(activeStep == questions?.length - 1) {
    // //   setTimeout(() => { completeAssessment(); }, 500);
    // // } else if(activeStep < questions?.length - 1) {
    // //   setTimeout(() => {
    // //     setActiveStep(activeStep + 1);
    // //     setPrevValue(questions?.[activeStep + 1].answer);
    // //   }, 20);

    
    // assessCtx.updateAnswer(questions?.[activeStep].id, questions?.[activeStep].answer);
    // assessCtx.updateTextAnswer(questions?.[activeStep].id, questions?.[activeStep].answerTxt);
    // if (activeStep === questions?.length - 1) {
    //   setTimeout(() => { completeAssessment(); }, 500);
    // } else if(activeStep < questions?.length - 1) {
    //   setTimeout(() => {
    //     setActiveStep(activeStep + 1);
    //     setPrevValue(questions?.[activeStep + 1].answer);
    //   }, 20);
    //   // setSection(questions?.[activeStep + 1].sectionId - sectionIdOffset);
    // } else if(currentHash != 0 && hasAdditional) {
    //   // if(searchParams.get("member") != null) {
    //     if(searchParams?.get("member")) {
    //       navigate("/assessment-additional/" + currentHash + "?" + searchParams, { replace: true });
    //     } else {
    //       navigate("/pre-additional-info/" + currentHash + "?" + searchParams, { replace: true });
    //     }
    // } else {
    //   // if(searchParams.get("member") != null) {
    //   if(searchParams.get("member") && currentHash) {
    //     navigate("/details/" + currentHash + "?" + searchParams, { replace: true });
    //   } else {
    //     console.log("\nSUBMITTING ANSWERS\n");
    //     setTimeout(() => { completeAssessment(); }, 500);
    //   }
    // }

    const addQPageId = 659;
    const detailsPageId = 730;
    if(activeStep < questions?.length - 1) {
      if(questions?.[activeStep]?.answer > 0) {
        setActiveStep((prev) => prev + 1);
      }
    } else {
      // navigateForwardFunc();
      // if (questions[activeStep]?.answer > 0 || questions[activeStep]?.answerTxt) {
      navigateToIntroPages();
      if (questions[activeStep]?.answer > 0) {
        // navigate(`/details/${id}/${detailsPageId}?${searchParams}`, { replace: true });
        // navigate(`/details/${id}`, { replace: true });
        completeAssessment();
        // navigate(`/details/${id}?${searchParams}`, { replace: true });
      }
    }

  };

  const submitAnswer = (answer) => {
    const jsonBody = {
      pageId: pageId,
      questions: [
        {
          "id": questions?.[activeStep]?.id,
          "answer": answer,
          "answerTxt": null
        }
      ],
    }
    console.log(jsonBody);
    fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment/' + id, {
      method: 'PUT',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(jsonBody)
    }).then(response => {
      return response.json();
    }).then(data => {
      if(data.status === "success") {
        // navigate("/assessment/" + id + "/" + pageId + "/?" + searchParams, { replace: true });
        // save the answer option so you see the selected option when going back & forth between questions 
        questions[activeStep].answer = answer;
        if(activeStep < (questions?.length - 1)) {
            setActiveStep(activeStep + 1);
            // setPrevValue(questions?.[activeStep + 1].answer);
        } else {
          // navigate(navigatePath, { replace: true });
          // navigateForwardFunc();
          const addQPageId = 718;
          // navigate(`/assessment-additional/${id}/${addQPageId}?${searchParams}`, { replace: true });
          navigate(`/details/${id}?${searchParams}`, { replace: true });
        }
        console.log("data", data);
      } else {
        // setIsSaving(false);
        // setOpenSnackbar(true);
      }
    }).catch((err) => console.error("Failed to send answer details...", err));
  }

  // console.log("activeStep", activeStep);
  // console.log("orderNo", questions?.[activeStep]?.orderNo, typeof questions?.[activeStep]?.orderNo);
  const navigatePath = (pgId) => "/assessment/" + id + "/" + pgId + "/?" + searchParams;
  const navigateForwardFunc = () => {
    if(pageId === "28") {
      navigate("/charts/" + id + "/29?" + searchParams, { replace: true });
    } else if(pageId === "30") {
      navigate("/charts/" + id + "/31?" + searchParams, { replace: true });
    } else if(pageId === "32") {
      navigate("/charts/" + id + "/33?" + searchParams, { replace: true });
    } else if(pageId === "34") {
      navigate("/charts/" + id + "/35?" + searchParams, { replace: true });
    } else if(pageId === "36") {
      navigate("/charts/" + id + "/37?" + searchParams, { replace: true });
    } else if(pageId === "38") {
      navigate("/assessment-additional/" + id + "/39?" + searchParams, { replace: true });
    }
  }
  const navigateBackwardFunc = () => {
    let qOrderNum = questions?.[activeStep]?.orderNo;
    console.log("qOrderNum", qOrderNum);
    console.log("pageId", pageId, typeof pageId);
    
    if (pageId === "28") navigate("/first-form/" + id + "?" + searchParams, { replace: true }); 
    else if (pageId === "30") {
      if(qOrderNum == 2) navigate("/charts/" + id + "/29?" + searchParams, { replace: true });
      else {
        navigate(navigatePath(28), { replace: true });
        setActiveStep(questions?.length - 1);
      }
    }
    else if (pageId === "32") {
      if(qOrderNum == 5) navigate("/charts/" + id + "/31?" + searchParams, { replace: true });
      else {
        navigate(navigatePath(30), { replace: true });
        setActiveStep(questions?.length - 1);
      }
    }
    else if (pageId === "34") {
      if(qOrderNum == 8) navigate("/charts/" + id + "/33?" + searchParams, { replace: true });
      else {
        navigate(navigatePath(32), { replace: true });
        setActiveStep(questions?.length - 1);
      }
    }
    else if (pageId === "36") {
      if(qOrderNum == 11) navigate("/charts/" + id + "/35?" + searchParams, { replace: true });
      else {
        navigate(navigatePath(34), { replace: true });
        setActiveStep(questions?.length - 1);
      }
    }
    else if (pageId === "38") {
      if(qOrderNum == 14) navigate("/charts/" + id + "/37?" + searchParams, { replace: true });
      else {
        navigate(navigatePath(36), { replace: true });
        setActiveStep(questions?.length - 1);
      }
    } else {
      navigate("/?" + searchParams, { replace: true });
    }
  }

  // const moveToNextQuestion = (answer) => {
  //   // questions[activeStep].answer = answer;
  //   // assessCtx.updateAnswer(questions?.[activeStep]?.id, questions?.[activeStep]?.answer);
  //   // assessCtx.updateTextAnswer(questions?.[activeStep]?.id, questions?.[activeStep]?.answerTxt);
  //   // if(activeStep < questions?.length - 1) {
  //   //   // setPrevValue(questions?.[activeStep]?.answer);
  //   //   setTimeout(() => {
  //   //     // setPrevValue(questions?.[activeStep]?.answer);
  //   //     setActiveStep(activeStep + 1);
  //   //   }, 500);
  //   // } else {
  //   //   // questions?.[activeStep].answer = answer;
  //   //   console.log("\n\nSUBMITTING ANSWERS\n\n");
  //   //   setTimeout(() => { completeAssessment(); }, 500);
  //   // }

  //   console.log("answer", answer);
  //   console.log("\n\n\n\nactiveStep:", activeStep, "\nquestions?.length:", questions?.length, "\n\n\n\n");
    
  //   submitAnswer(answer);

  // }

  

  const moveToNextQuestion = (answer) => {

    questions[activeStep].answer = answer;
    // assessCtx.updateAnswer(questions?.[activeStep]?.id, questions?.[activeStep]?.answer);
    // assessCtx.updateTextAnswer(questions?.[activeStep]?.id, questions?.[activeStep]?.answerTxt);

    if (activeStep < questions?.length - 1) {
      // setPrevValue(questions?.[activeStep]?.answer);
      setTimeout(() => {
        // setPrevValue(questions?.[activeStep]?.answer);
        setActiveStep(activeStep + 1);
      }, 500);
    } else {
      // questions?.[activeStep].answer = answer;
      console.log("\n\nSUBMITTING ANSWERS\n\n");
      setTimeout(() => {
        navigateToIntroPages();
        completeAssessment();
      }, 500);
    }

    
    console.log("answer", answer);
    console.log("\nactiveStep:", activeStep, "\nquestions?.length:", questions?.length, "\n");
    
    // setTimeout(() => {
    //   submitAnswer(answer);
    // }, 250);
  }

  function navigateToIntroPages() {

    let qOrderNo = questions?.[activeStep]?.orderNo;
    if (qOrderNo == 7) {
      navigate("/intro/" + id + "/734?" + searchParams, { replace: true });
    } else if (qOrderNo == 14) {
      navigate("/intro/" + id + "/736?" + searchParams, { replace: true });
    } else if (qOrderNo == 21) {
      navigate("/intro/" + id + "/738?" + searchParams, { replace: true });
    }
  }

  function navigateBackToIntroPages() {
    let qOrderNo = questions?.[activeStep]?.orderNo;
    if (qOrderNo == 8) {
      navigate("/intro/" + id + "/734?" + searchParams, { replace: true });
    } else if (qOrderNo == 15) {
      navigate("/intro/" + id + "/736?" + searchParams, { replace: true });
    } else if (qOrderNo == 22) {
      navigate("/intro/" + id + "/738?" + searchParams, { replace: true });
    } else if (qOrderNo == 1) {
      navigate("/?" + searchParams, { replace: true });
    }
  }

  const completeAssessment = () => {

    setIsLoading(true);
    
    const jsonBody = { 
      pageId: pageId,
      questions: questions,
    }
    console.log(jsonBody);
    fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment/' + id, {
      method: 'PUT',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(jsonBody)
    }).then(response => {
      return response.json();
    }).then(data => {
      setIsLoading(false);
      console.log('data@',data)
      if(data.status === "success") {
        const resultsPageId = 711;
        localStorage.removeItem("assessmentAnswers");
        console.log('success---')
        if ( pageId == 739 ) {
          navigate(`/details/${id}?${searchParams}`, { replace: true });
        }
        console.log("data----", data);
      } else {
        // setIsSaving(false);
        // setOpenSnackbar(true);
      }
    }).catch((err) => {
      console.error("Failed to send answer details...", err);
      setIsLoading(false);
    });

    if ( pageId == 728 ) {
      console.log('pageId-----',pageId)
      navigate("/intro/" + id + "/734?" + searchParams, { replace: true });
    }
    else if ( pageId == 735 ) {
      navigate("/intro/" + id + "/736?" + searchParams, { replace: true });
    }
    else if ( pageId == 737 ) {
      navigate("/intro/" + id + "/738?" + searchParams, { replace: true });
    }
	}

  const nextDisabled = () => {
    if(questions?.[activeStep]?.answerType === "textarea" && questions?.[activeStep]?.answerTxt?.length > 0) {
      return { display: "flex" };
    }
    if(questions?.[activeStep]?.answer > 0) {
      return { display: "flex" };
    };
    return { display: "none" };
  }
  // console.log("questions?.[activeStep]", questions?.[activeStep]);
  // console.log("questions?.[activeStep].answer", questions?.[activeStep]?.answer);
  // console.log("questions?.[activeStep]", questions?.[activeStep]?.id);
  // console.log("\n\nquestions?.[activeStep]", activeStep, (questions?.length - 1), "\n\n");
  
  // const nextDisabled = (answer) => {
  //   if(questions?.[activeStep]?.mandatory === "no") {
  //     return {display: "block"};
  //   } else {
  //     // if(questions?.[activeStep]?.answerType == "slider" && questions?.[activeStep]?.answer != 0) {
  //     if(questions?.[activeStep]?.answerType == "slider" && !questions?.[activeStep]?.answer > 0) {
  //       return { display: "block" };
  //     } else if(questions?.[activeStep]?.answerType == "radio" && questions?.[activeStep]?.answer != 0) {
  //       return {display: "block"};
  //     } else if(questions?.[activeStep]?.answerType == "textarea" && questions?.[activeStep]?.answerTxt != "") {
  //       return {display: "block"};
  //     }
  //   } 
  //   return {display: "none"};
  // }

  const updateTextAnswer = (id, textVal) => {
    setQuestions(questions?.map((q) => {
      if(q.id === id) q.answerTxt = textVal;
      return q;
    }))
  }

  console.log("questions", questions);

  const getQuestionsPerSection = () => {
    let arr = questions?.map(q => q.sectionId);
    let count = {};
    arr?.forEach(element => {
      count[element] = (count[element] || 0) + 1;
    });
    return Object.values(count);
  }
  
  // const questionsPerSection = getQuestionsPerSection();
  const questionsPerSection = [7, 7, 7, 7];
  // console.log("questionsPerSection", questionsPerSection);
  // console.log("currentProgress", activeStep);

  const getStepContent = (step) => {
    return (
      <Question question={questions?.[step]} questionCount={questionCount} questionAnswered={moveToNextQuestion} currentValue={prevValue} updateTextAnswer={updateTextAnswer} />
    );
  };

  if (isLoading) {
		return <div className="container assessment loading"><CircularProgress style={{ color: '#7CCEE7' }} size={60} /></div>
	}

  

  return (
    <>
    {/* {windowWidth < 831 ? <>
      <ProgressBarSingle currentProgress={(parseInt(questions?.[activeStep]?.orderNo) - 1)} totalNumOfQuestions={questions?.length} />
      <p className="current-section">{currentSectionName}</p>
    </> : ""} */}
    <div className="container assessment">
      <div className="section-progress-top">
        <ProgressBar currentProgress={(parseInt(questions?.[activeStep]?.orderNo) - 1)} questionsPerSection={questionsPerSection} sections={sections} count={questionCount} currentSectionName={currentSectionName} />
      </div>
      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={activeStep}></Stepper>
      </Box>
      <div>
        <div className="steps-container">
          <div>
            <div className="steps">

              { windowWidth > 830 && <div className="spacer"></div>}

              {/* {windowWidth > 830 ?
                <>
                  <ProgressBar currentProgress={activeStep} questionsPerSection={questionsPerSection} sections={sections} count={questionCount} />
                  <ProgressBar currentProgress={(parseInt(questions?.[activeStep]?.orderNo) - 1)} questionsPerSection={questionsPerSection} sections={sections} count={16} />
                </>
                : <>
                  <ProgressBarSingle currentProgress={activeStep} totalNumOfQuestions={questionCount} />
                  <ProgressBarSingle currentProgress={(parseInt(questions?.[activeStep]?.orderNo) - 1)} totalNumOfQuestions={16} />
                  <p className="current-section">{currentSectionName}</p>
                </>
              } */}
              {/* <ProgressBarSingle currentProgress={activeStep} totalNumOfQuestions={questionCount} sections={sections} currentSectionName={currentSectionName} /> */}
              {/* <div className="spacer"></div> */}
              {/* <div className="spacer"></div> */}
              {/* <div className="spacer mobile-spacer"></div> */}
              {/* <div className="spacer mobile-spacer"></div> */}
              
              {/* <div className="progress-indicator"><strong>{activeStep + 1}</strong>/16</div> */}
              <div className="progress-indicator"><strong>{questions?.[activeStep]?.orderNo}</strong>/{questionCount}</div>

              {getStepContent(activeStep)}

              <div className="step-buttons">
                {windowWidth > 550 && <Button onClick={prevQuestion} className='prev-btn' >
                  <img src={prevArrow} alt="previous button" />&nbsp;&nbsp;PREV
                </Button> }
                {windowWidth <= 550 && <Button onClick={prevQuestion}>
                  <img src={prevArrow} alt="previous button" />&nbsp;&nbsp;
                </Button> }
                {windowWidth > 550 && <Button className="next-btn" onClick={nextQuestion} style={nextDisabled()}>
                  <span style={{ borderBottom: "1px solid #6EC5B8" }}>NEXT</span><img style={{ paddingLeft: "8px" }} src={nextArrow} alt="next button" />
                </Button> }
                {windowWidth <= 550 && <Button className="next-btn" onClick={nextQuestion} style={nextDisabled()}>
                  <img style={{ paddingLeft: "8px" }} src={nextArrow} alt="next button" />
                </Button> }
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  );
}

export default AssessmentV3;

