import React, { useState } from 'react';

import LinearProgress from '@mui/material/LinearProgress';

import useWindowDimensions from '../Hooks/useWindowDimensions';

const ProgressBar = props => {

  console.log("ProgressBar props", props);
  
  const { windowWidth } = useWindowDimensions();

  let activeColor           = '#7CCEE7';
  let passiveColor          = '#343333';
  let sections              = [];
  let numOfQuestions        = props?.count;
  const currentProgress     = props?.currentProgress;
  const currentSectionName  = props?.currentSectionName;
  const questionsPerSection = props?.questionsPerSection;
  const maxProgressPerSec   = questionsPerSection?.map((item, idx) => questionsPerSection.slice(0, idx + 1).reduce((a, b) => a + b));
  // min progress needed in first section is always 0 
  const minProgressPerSec   = [0, ...maxProgressPerSec];
  console.log("minProgress", minProgressPerSec);
  console.log("maxProgress", maxProgressPerSec);

  props?.sections?.forEach((section, idx) => {
    sections.push({ 
      name: section.section, 
      style: { color: passiveColor, fontWeight: '600' }, 
      questions: questionsPerSection[idx], 
      progress: 0, minProgress: minProgressPerSec[idx], maxProgress: maxProgressPerSec[idx] 
    })
  })
  
  let progressWidth         = (100 / sections.length) + '%';
  if(window.innerWidth < 650) {
    progressWidth           = '100%';
  }

  console.log("Progress Width", progressWidth);

  sections?.forEach((sec, idx) => {
    if(currentProgress >= sec.minProgress && currentProgress + 1 <= sec.maxProgress) {
      sec.progress = (((currentProgress - sec.minProgress) + 1) / sec.questions) * 100;
      sec.style    = { color: activeColor,  fontWeight: '700' };
    } else {
      if((currentProgress+1) > (sec.maxProgress)) {
        sec.progress = 101;
      } else sec.progress = -1;
      sec.style    = { color: passiveColor, fontWeight: '400' };
    }
  })

  let progressPercentage = Math.round(((props?.currentProgress + 1) / numOfQuestions) * 100);
  console.log("ROUNDS", props?.currentProgress, numOfQuestions, progressPercentage );
  // calculate percentage per section per question 
  const calcPercentPerQuestion = () => {
    let questionsPerSection = props?.questionsPerSection;
    let sections = props?.questionsPerSection?.length;
    // 100% divided by number of sections 
    let eachSection = parseFloat((100 / sections).toFixed(2));
    // console.log(eachSection);
    let progress = 0;
    let progressPerQuestionArr = [];
    for (let i = 0; i < sections; i++) {
      progress = eachSection / questionsPerSection[i];
      // console.log(progress);
      for(let j = 0; j < questionsPerSection[i]; j++) {
        progressPerQuestionArr.push(parseFloat(progress.toFixed(2)));
      }
    }

    let accumulator = 0
    progressPerQuestionArr?.forEach((value, index) => {
      if(index <= props?.currentProgress) { accumulator += value; }
      return accumulator;
    })
    // console.log("accumulator", accumulator);
    return Math.round(accumulator);
  }
  const progressPercent = calcPercentPerQuestion();
  console.log("%%",progressPercent);

  console.log("sections", sections);


  return (
    <div style={{ /* padding: windowWidth < 550 ? "45px 15px 15px" : "45px 122px 15px" */ }}>
      <p className="progress-percent-text" variant="determinate" style={{
        marginLeft: progressPercentage === 100 ? `calc(${progressPercentage}% - 3ch)` : `calc(${progressPercentage}% - 2ch)`, marginBottom: windowWidth < 651 ? "5px" : 0,
      }}>{`${progressPercentage}%`}</p>
      
      {windowWidth < 831 ? <div style={{ margin: "0 auto" }}>
        <LinearProgress className="custom-bar" variant="determinate" value={progressPercentage} 
        sx={{ backgroundColor: "#D8D8D8", color: "#7CCEE7", borderRadius: "30px", 
        height: "6px", maxWidth: "90%", margin: "0 auto",
          "& .MuiLinearProgress-barColorPrimary": { backgroundColor: "#7CCEE7", }
        }}
        />
        <p className="current-section" style={{ padding: "0 10px" }}>{currentSectionName}</p>
      </div> : 

      <div className="progress-bar">
        {sections.map((sec) => {
          console.log("sec", sec);
          return (
            <div key={sec.name} className="bar-section" style={{ width: progressWidth }}>
              <div className="bar"><LinearProgress className="custom-bar" variant="determinate" value={sec.progress} /></div>
              <div className="section-title" style={sec.style}>{sec.name.toUpperCase()}</div>
            </div>
          )
        })}
      </div>}
    </div>
  );
}
export default ProgressBar;



// return (
//   <div>
//     <p className="progress-percent-text" variant="determinate" style={{
//       marginLeft: `calc(${progressPercentage}% - 2ch)`
//     }}>{`${progressPercentage}%`}</p>
//     <div className="progress-bar">                
//       <div className="bar-section" style={{width: progressWidth}}>
//         <div className="bar"><LinearProgress className="custom-bar" variant="determinate" value={progress1} /></div>
//         <div className="section-title" style={section1style}>STRATEGY</div>
//       </div>
//       <div className="bar-section" style={{width: progressWidth}}>
//         <div className="bar"><LinearProgress className="custom-bar" variant="determinate" value={progress2} /></div>
//         <div className="section-title" style={section2style}>PARTNERSHIP</div>
//       </div>
//       <div className="bar-section" style={{width: progressWidth}}>
//         <div className="bar"><LinearProgress className="custom-bar" variant="determinate" value={progress3} /></div>
//         <div className="section-title" style={section3style}>ENGAGEMENT</div>
//       </div>
//       <div className="bar-section" style={{width: progressWidth}}>
//         <div className="bar"><LinearProgress className="custom-bar" variant="determinate" value={progress4} /></div>
//         <div className="section-title" style={section4style}>MISSION & VISION</div>
//       </div>
//     </div>

//   </div>
// );

