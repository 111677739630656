import { useState, useRef, useEffect, useMemo } from 'react';
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import VideoThumbnail from '../../Assets/Images/video-thumbnail.png';
// import VideoThumbnailMobile from '../Assets/Images/video-thumbnail-mobile.png';
// import PlayButton from '../Assets/Images/play-button.png';
import playBtn from '../../Assets/Images/play-btn.svg';
import playBtnHovered from '../../Assets/Images/play-btn-hover.svg';
import Clock from '../../Assets/Images/Clock.svg';
import { Close, Pause, PlayArrow } from "@mui/icons-material";
import useWindowDimensions from '../../Hooks/useWindowDimensions';

import { Button, CircularProgress } from '@mui/material';
const Start = props => {

  const { windowWidth } = useWindowDimensions();

  const [isLoading, setIsLoading] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const [isPlayingVideo, setIsPlayingVideo] = useState(false);
  const [isVidPaused, setIsVidPaused] = useState(false);
  const [btnHovered, setBtnHovered] = useState(false);
  // const [startPlaying, setStartPlaying]   = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [isSeeking, setIsSeeking] = useState(false);

  const vidRef = useRef(null);
  const introElmRef = useRef(null);
  const videoElmRef = useRef(null);

  const display = searchParams.get("display");
  const navigate = useNavigate();



  // useEffect(() => {
  //   if(display === "preview") {
  //     console.log("changed background img for embedded preview display...");
  //     document.body.style.background = `#242424 url(${introBgImg}) repeat`;
  //   } else {
  //     document.body.style.background = `#414042`;
  //   }
  // }, [display]);

  useEffect(() => {
    const introElm = introElmRef.current;
    const videoElm = videoElmRef.current;
    if (windowWidth <= 830) {
      introElm.style.width = "100%";
      videoElm.style.width = "100%";
      introElm.style.maxHeight = "unset";
    } else if (windowWidth > 830) {
      if (isPlayingVideo) {
        introElm.style.width = "0%";
        videoElm.style.width = "100%";
      } else {
        introElm.style.width = "53%";
        videoElm.style.width = "47%";
        videoElm.style.maxHeight = "570px";
        introElm.style.maxHeight = "570px";
      }
    }
  }, [isPlayingVideo, windowWidth]);


  const createAssessment = () => {

    // TODO: change the pageId according to what the page ID is for this assessment
    const pageId = 717;

    let leaderId = 0;
    let userId = 0;
    let isMember = false;
    if (searchParams?.get("leader")) {
      leaderId = searchParams?.get("leader");
      userId = searchParams?.get("user");
      isMember = true;
    }

    setIsLoading(true);
    const clientId = process.env.REACT_APP_CLIENT_ID;
    fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment', {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ clientId, isMember, leaderId, userId })
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data.status === "success") {
        setIsLoading(false);
        navigate("/intro/" + data.id + "/729?" + searchParams, { replace: true });
        // navigate("/assessment/" + data.id + "/" + pageId + "?" + searchParams, { replace: true });
        console.log("data", data);
      } else {
        // setIsSaving(false);
        // setOpenSnackbar(true);
      }
    }).catch((err) => {
      setIsLoading(false);
      console.error("Failed to create new assessment...", err);
    });
  }


  const howItWorksLink = () => {
    if (searchParams == "") {
      return "/how-it-works";
    } else {
      return "/how-it-works?" + searchParams;
    }
  }
  // console.log(searchParams.get("display"));

  const assessmentLink = () => {
    return "/assessment?" + searchParams;
  }

  const preAssessmentQuestionsLink = () => {
    navigate("/pre-additional-questions?" + searchParams);
  }

  const introPageLink = () => {
    createAssessment();
    // navigate("/intro/" + id + "?" + searchParams);
  }

  const playVideo = (e) => {

    if(windowWidth > 830) {
      const introElm = introElmRef.current;
      const videoElm = videoElmRef.current;

      introElm.style.cssText = "width: 0%; padding: 0; overflow: hidden; max-height: 520px;";
      videoElm.style.cssText = "width: 100%; margin: 0 auto; background-color: #DFF8FF; border-radius: 10px;";
      // if(display === "preview") {
      //   videoElm.style.background = `#242424 url(${introBgImg}) repeat`;
      // } else videoElm.style.backgroundColor = "#435B63";
    }

    vidRef.current.play();
    setIsPlayingVideo(true);
    setIsVidPaused(false);
    // if(!startPlaying) {
    //   setStartPlaying(true);
    // }
  }

  const pauseVideo = (e) => {

    vidRef.current.pause();
    // setIsPlayingVideo(false);
    setIsVidPaused(true);
    setBtnHovered(false);

    if(windowWidth > 830) {
      const introElm = introElmRef.current;
      const videoElm = videoElmRef.current;

      introElm.style.cssText = "width: 53%; padding: 14px 34px 0 72px; overflow: hidden; ";
      videoElm.style.cssText = "width: 47%; margin: 0 auto; background-color: #51484E; border-top-left-radius: 10px; border-bottom-left-radius: 10px;";
      // if(display === "preview") {
      //   videoElm.style.background = `#242424 url(${introBgImg}) repeat`;
      // } else videoElm.style.backgroundColor = "#435B63";
    }
    setIsPlayingVideo(false);
  }

  const handlePlayPause = () => {
    if (isPlayingVideo) {
      pauseVideo();
    } else {
      playVideo();
    }
    setIsPlayingVideo(!isPlayingVideo);
  };

  const handleTimeUpdate = () => {
    setCurrentTime(vidRef.current.currentTime);
  };

  const handleSeek = (e) => {
    const newTime = e.target.value;
    vidRef.current.currentTime = newTime;
    setCurrentTime(newTime);
  };

  const handleVideoPlaybackEnd = () => {
    setBtnHovered(false)
    setIsPlayingVideo(false);
    setIsVidPaused(false);
    showPlayButton();
    
    const introElm = introElmRef.current;
    const videoElm = videoElmRef.current;

    if (windowWidth > 830) {

      introElm.style.cssText = "width: 53%; padding: 0px 3%; overflow: hidden; max-height: 520px;";
      videoElm.style.cssText = "width: 47%; margin: 0 auto; background-color: #51484E; border-top-left-radius: 10px; border-bottom-left-radius: 10px;";
      
      // if(display === "preview") {
        //   videoElm.style.background = `#242424 url(${introBgImg}) repeat`;
        // } else videoElm.style.backgroundColor = "#435B63";
      } else if (windowWidth <= 830) {
        introElm.style.width = "100%";
        videoElm.style.width = "100%";
      }
  }

  const showThumbnail = useMemo(() => {

    if (isVidPaused) {
      return <img alt="video thumbnail" className="thumbnail" src={VideoThumbnail} style={{ opacity: '1' }}
      // onClick={(e) => pauseVideo(e)} 
      />;
    }
    if (!isPlayingVideo && !isVidPaused) {
      return <img alt="video thumbnail" className="thumbnail" src={VideoThumbnail} style={{ opacity: '1' }}
      // onClick={(e) => pauseVideo(e)} 
      />;
    } else {
      // return <img alt="video thumbnail" className="thumbnail" src={VideoThumbnail} style={{ opacity: '0' }} onClick={(e) => pauseVideo(e)} />;
      return null;
      // if(startPlaying) {
      //   return null
      // } else {
      //   return <img alt="video thumbnail" className="thumbnail" src={VideoThumbnail} />
      // }
    }
    // }, [isPlayingVideo, startPlaying]);
  }, [isPlayingVideo, isVidPaused]);

  const showPlayButton = () => {
    if (isPlayingVideo) {
      return null
    } else {
      return <div className="play-btn-img" onClick={(e) => playVideo(e)} onMouseEnter={() => setBtnHovered(true)} onMouseLeave={() => setBtnHovered(false)} >
        <img alt="play button" src={btnHovered ? playBtnHovered : playBtn} />
        <span>Watch video</span>
      </div>
    }
  }

  function formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    if (!seconds) return "0:00";
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  }

  const handleSeekStart = () => {
    setIsSeeking(true);
  }

  const handleSeekEnd = () => {
    setIsSeeking(false);
  }

  // const vidsrcURL = "https://40parables-assets.s3.amazonaws.com/bleat-LWP-Assessment-Promo.mp4"; // 4k video
  const vidsrcURL = "https://40parables-assets.s3.amazonaws.com/Bleat-Lwp-Assessment-Promo-720p.mp4";

  return (    
    <div className="start">
      <div className="video" ref={videoElmRef}>
        <video ref={vidRef} controls={false}
          onClick={(e) => e.preventDefault()}
          onEnded={handleVideoPlaybackEnd}
          onPlay={() => setIsPlayingVideo(true)}
          onPause={() => {
            setIsPlayingVideo(false);
            setIsVidPaused(true);
            showPlayButton();
          }}
          onTimeUpdate={handleTimeUpdate}
          onSeeking={handleSeekStart}
          onSeeked={handleSeekEnd}
          >
            <source src={vidsrcURL} type="video/mp4" />
        </video>
        {/* custom video controls */}
        {
        isPlayingVideo && <div className="custom-controls">
          <button onClick={handlePlayPause}>
            {isPlayingVideo ? <Pause /> : <PlayArrow />}
          </button>
          <input className="custom-slider"
            type="range" min="0" max={vidRef.current ? vidRef.current.duration : 0}
            value={currentTime} onChange={handleSeek}
          />
          <span>
            {formatTime(Math.floor(currentTime))} /{' '}
            {formatTime(Math.floor(vidRef.current ? vidRef.current.duration : 0))}
          </span>
        </div>
        }
        {/* custom video controls */}
        {/* {showThumbnail()} */}
        {showThumbnail} {/* using useMemo */}
        {showPlayButton()}
        {isPlayingVideo && <Close className="close-video" style={{ position: "absolute", height: "20px", width: "20px", color: "#fefefede", opacity: 0.85, zIndex: 99, padding: "15px", top: "5px", cursor: "pointer", right: windowWidth > 1100 ? "15px" : "5px" }} onClick={(e) => pauseVideo(e)} />}
      </div>
      <div className="intro-txt" ref={introElmRef}>
        <h1>How are your prayer practices joining your work with God's presence?</h1>
        <h2>Increase your fruitfulness by receiving God's invitation to personal and organizational prayer.</h2>
        <ol className="intro-points-list">
          <li><strong>Begin with prayer</strong> <span>- Ask God to create a prayerful heart in you so you can earnestly answer the questions in this assessment.</span></li>
          <li><strong>Discern your next step</strong> <span>- Take this four-part assessment to evaluate key aspects of your life as a praying leader.</span></li>
        </ol>

        <div className="completion">
          {/* <div className='btn-container'> */}
          {/* <Link to={assessmentLink()} className="cta">Get Started</Link> */}
          {/* <Button className="cta" onClick={() => introPageLink()}>FIND OUT</Button> */}
          <Button className="cta" onClick={() => introPageLink()}>{isLoading ? <CircularProgress size={20} style={{ color: "#fff" }} /> : "REFLECT AND SEE"}</Button>
          {/* </div> */}
          <div className='time-estimate'>
            <div className='img-container'><img className='clock-icon' src={Clock} alt="estimated time" /></div><span>{searchParams.get("leader") ? "6 min" : "6 min"}</span>
          </div>
        </div>

      </div>
    </div>



  );
}

export default Start;
