import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";

import prevArrow from "../Assets/Images/prev-arrow.svg";
import nextArrow from "../Assets/Images/next-arrow.svg";
import testimonialImage from "../Assets/Images/testimonial.jpg";
// import scheduledImage from "../Assets/Images/walter-kim.png";
import quotationImage from "../Assets/Images/quotation.svg";
import InviteInfo from "../Components/InviteInfo";
import Testimonial from "../Components/Testimonial";
import Resources from "../Components/Resources";
import DonutChart from "../Components/DonutChart";
import SendInvite from "../Components/SendInvite";
// import DonutChartAccordion from "../Components/DonutChartAccordion";

import { Button, Accordion, AccordionSummary, AccordionDetails, Box, Typography, LinearProgress, CircularProgress, Switch } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Add, Remove } from "@mui/icons-material";
import sampleDashboardTeamImg from '../Assets/Images/sample-dashboard-team-img.png';
import sampleDashboardTeamImgMobile from '../Assets/Images/sample-dashboard-team-img-mobile.png';
import sampleDashboardIndividualImg from '../Assets/Images/sample-dashboard-individual-img.png';
import sampleDashboardIndividualImgMobile from '../Assets/Images/sample-dashboard-individual-img-mobile.png';
import CopyImg from '../Assets/Images/copycontent.svg';
import ImportantImg from '../Assets/Images/importantsign.svg';

import useWindowDimensions from '../Hooks/useWindowDimensions';
import { sectionsText } from "../globalFunctions";
import Promo from "../Components/Promo/Promo";

const CustomExpandIcon = () => {
  return (
    <Box
      sx={{
        ".Mui-expanded & > .collapsIconWrapper": {
          display: "none"
        },
        ".expandIconWrapper": {
          display: "none"
        },
        ".Mui-expanded & > .expandIconWrapper": {
          display: "block"
        }
      }}
    >
      <div className="expandIconWrapper"><Remove /></div>
      <div className="collapsIconWrapper"><Add /></div>
    </Box>
  );
};

const Results = (props) => {
  let navigate = useNavigate();
  const { id } = useParams();

  const { windowWidth } = useWindowDimensions();
  const [isLoading, setIsLoading] = useState(true);
  const [isMember, setIsMember] = useState(false);
  const [apiData, setApiData] = useState();
  const [name, setName] = useState("");
  const [score, setScore] = useState(0);
  const [answers, setAnswers] = useState([])
  const [sections, setSections] = useState([]);
  const [subScore1, setSubScore1] = useState(0);
  const [subScore2, setSubScore2] = useState(0);
  const [subScore3, setSubScore3] = useState(0);
  const [subScore4, setSubScore4] = useState(0);
  const [subScore5, setSubScore5] = useState(0);
  const [subScores, setSubScores] = useState();
  const [currentView, setCurrentView] = useState(0);
  const [toggleIndividual, setToggleIndividual] = useState(1);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [chartsData, setChartsData] = useState();
  const [sectionsData, setSectionsData] = useState();
  const [sectionScores, setSectionScores] = useState([0, 0, 0, 0]);

  useEffect(() => {
    // document.body.style.backgroundColor = "#435B63";
    fetchResults();
  }, []);

  useEffect(() => {
    // basic check for API data reliability 
    if (apiData?.score > 0 && apiData?.status === "success") {

      const score1 = apiData?.chart1Values?.find(val => val.name === apiData?.selected1Answer)?.y;
      const score2 = apiData?.chart2Values?.find(val => val.name === apiData?.selected2Answer)?.y;
      const score3 = apiData?.chart3Values?.find(val => val.name === apiData?.selected3Answer)?.y;
      const score4 = apiData?.chart4Values?.find(val => val.name === apiData?.selected4Answer)?.y;
      const score5 = apiData?.chart5Values?.find(val => val.name === apiData?.selected5Answer)?.y;

      setChartsData([
        {
          title: "The community around us is aware of our mission.",
          order: 1, values: apiData?.chart1Values, answer: { name: apiData?.selected1Answer, y: score1 }
        },
        {
          title: "How many engagement opportunities did you host in the past year open to the larger community?",
          order: 2, values: apiData?.chart2Values, answer: { name: apiData?.selected2Answer, y: score2 }
        },
        {
          title: "Throughout last year, how many community members participated in these engagement opportunities?",
          order: 3, values: apiData?.chart3Values, answer: { name: apiData?.selected3Answer, y: score3 }
        },
        {
          title: "Over time, our church's engagement with our community has been...",
          order: 4, values: apiData?.chart4Values, answer: { name: apiData?.selected4Answer, y: score4 }
        },
        {
          title: "Our community frequently utilizes our church's building facilities and programs.",
          order: 5, values: apiData?.chart5Values, answer: { name: apiData?.selected5Answer, y: score5 }
        },
      ]);
    }
  }, [apiData]);

  console.log("chartsData", chartsData);

  useEffect(() => {
    if (apiData?.variables) {
      const sectionData1 = apiData?.variables?.find((obj) => obj.variable === "priority_total");
      const sectionData2 = apiData?.variables?.find((obj) => obj.variable === "practising_total");
      const sectionData3 = apiData?.variables?.find((obj) => obj.variable === "modelling_total");
      const sectionData4 = apiData?.variables?.find((obj) => obj.variable === "promotion_total");
      const scoreTotal = apiData?.variables?.find((obj) => obj.variable === "score_total");
      // setScore(scoreTotal.score);
      setSectionScores([sectionData1.score, sectionData2.score, sectionData3.score, sectionData4.score]);
      setSubScore1(sectionData1.score);
      setSubScore2(sectionData2.score);
      setSubScore3(sectionData3.score);
      setSubScore4(sectionData4.score);
      setSubScores([sectionData1.score, sectionData2.score, sectionData3.score, sectionData4.score]);
    }
  }, [apiData]);

  // to animate subScores on results page 
  useEffect(() => {
    // this settimeout is causing donutcharts selected pie enlargement to disappear so commented it out 
    // const timer = setTimeout(() => {
    //   setSubScores([subScore1, subScore2, subScore3, subScore4, subScore5]);
    // }, 250);
    // return () => {
    //   clearTimeout(timer);
    // };

    // setSubScores([subScore1, subScore2, subScore3, subScore4]);

  }, [subScore1, subScore2, subScore3, subScore4]);


  const fetchResults = () => {
    // fetch(process.env.REACT_APP_API_URI + "/v1/assessment/" + id, {
    fetch(process.env.REACT_APP_API_URI + "/v3/user/assessment/" + id + "/results", {
      method: "GET",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.status === "success") {
          console.log('assesment data',data);

          setApiData(data);
          setName(data.name);
          // setScore(data.score);
          setScore(data.variables?.find((obj) => obj.variable === "score_total")?.score || 0);
          setAnswers(data?.answers)
          setSections(data.sections);
          setSubScore1(data.subScore1);
          setSubScore2(data.subScore2);
          setSubScore3(data.subScore3);
          setSubScore4(data.subScore4);
          setSubScore5(data.subScore5);
          setIsMember(data.isMember);
          // console.log(data);

          setIsLoading(false);

        } else {
          // setOpenSnackbar(true);
        }
      });
  };

  // const showTitleText = () => {
  //   if (score > 0 && score <= 50) {
  //     return <h1><span className="user-name">{name}</span>, your ChMS is Sub-optimal</h1>
  //   } else if (score > 50 && score <= 80) {
  //     return <h1><span className="user-name">{name}</span>, your ChMS is Adequate</h1>
  //   } else if (score > 80) {
  //     return <h1><span className="user-name">{name}</span>, your ChMS is Perfect</h1>
  //   }
  // };

  const sectionsAndSubScores = sections?.map((sec, idx) => ({ section: sec.section, score: subScores[idx] }));
  // remove the last Feedback section from Results and Dashboard
  // sectionsAndSubScores.pop();
  console.log("sectionsAndSubScores", sectionsAndSubScores);

  const sortedSectionsAndSubScores = [...sectionsAndSubScores]?.sort((a, b) => a.score - b.score);
  console.log("sortedSectionsAndSubScores", sortedSectionsAndSubScores);

  // get the object with the highest score
  let highestScoreObject = sortedSectionsAndSubScores?.[(sortedSectionsAndSubScores?.length) - 1];

  // if there are duplicate highest scores, find the first occurrence
  if (highestScoreObject?.score === sortedSectionsAndSubScores[(sortedSectionsAndSubScores?.length) - 2]?.score) {
    let currentIndex = sortedSectionsAndSubScores?.length - 2;
    while (currentIndex >= 0 && sortedSectionsAndSubScores?.[currentIndex]?.score === highestScoreObject.score) {
      highestScoreObject = sortedSectionsAndSubScores[currentIndex];
      currentIndex--;
    }
  }

  // console.log("sortedSectionsAndSubScores[sortedSectionsAndSubScores?.length - 1]", sortedSectionsAndSubScores[sortedSectionsAndSubScores?.length - 1]);
  // console.log("highestScoreObject", highestScoreObject);

  const showTitleText = () => {
     if (score <= 50) {
      return <h1>{name}, based on what we’ve learned from global leaders, there are some places God may be inviting you to grow.</h1>
    } else if (score > 50 && score <= 75) {
      return <h1>{name}, it looks like you are growing in your practice of prayer.</h1>
    } else if (score > 75) {
      return <h1>{name}, let’s celebrate God’s goodness. You’ve drawn close to Him and encouraged others in your organization to do the same. We are rejoicing with you.</h1>
    }
  };

  const showResultsFooter = () => {
    return <div className="results-footer-container">
      <p>Get access to additional prayer tools like this assessment at <a href="https://www.leadwithprayer.com/" target="_blank" rel="noreferrer">www.leadwithprayer.com</a></p>
    </div>
  }

  const showScoreText = () => {
    const section = sectionsText?.[sortedSectionsAndSubScores[0]?.section?.toLowerCase()];
    const allScoresSame = sortedSectionsAndSubScores?.every(obj => obj.score === sortedSectionsAndSubScores?.[0]?.score);

    // if(allScoresSame) {
    //   return <div className="result-text">
    //     <p style={{ margin: "10px auto 30px" }}>We are glad to see that you maintain a balanced focus across all fronts of your strategy planning and execution.</p>
    //     <p style={{ margin: "10px auto 5px" }}>Here are some tips to take your strategy impact to the next level:</p>
    //     <div style={{ margin: "5px auto 10px 10px" }}>{sectionsText?.sameScores?.map((text, idx) => <p key={idx} style={{ margin: "2px auto" }}>{idx + 1}.&nbsp;&nbsp;{text}</p>)}</div>
    //   </div>
    // } else {
    //     return <div className="result-text">
    //     <p style={{ margin: "10px auto 30px" }}>Keep up the good work on the <span style={{fontWeight: "700"}}>{highestScoreObject?.section?.toLowerCase()}</span> front and pay special attention to <span style={{fontWeight: "700"}}>{sortedSectionsAndSubScores[0]?.section?.toLowerCase()}</span>.</p>
    //     <p style={{ margin: "10px auto 5px" }}>Here are some tips:</p>
    //     <div style={{ margin: "5px auto 10px 10px" }}>{section?.map((text, idx) => <p key={idx} style={{ margin: "2px auto" }}>{idx + 1}.&nbsp;&nbsp;{text}</p>)}</div>
    //   </div>
    // }

    if (score <= 50) {
      return <div className="result-text">
        <p style={{ margin: "5px auto" }} className="heading">Here are your next steps:</p>
        {/* <p>1. If you have not already done so, try using a free AI tool, like ChatGPT, for just 5 minutes to see its capabilities. Experiment with prompts related to your work, e.g. “Write a weekly newsletter excerpt in 100 words around my sermon script.” Paste your sermon script for the AI in the same prompt. See how it responds.</p>
        <p>2. Reach out to 5 church leaders using group text to ask if they use AI to increase their ministry impact. This way, you can recognize if churches around you are embracing AI and how.</p> */}
        {/* <p>1. Invite your leadership team to take this test.</p>
        <p>2. Unlock your team's individual responses and team scores*.</p>
        <p>3. Receive four practical tips about AI for your unique church context.</p> */}
        <ol>
          <li>Invite your leadership team to take this test.</li>
          <li>Unlock your team's individual responses and team scores*.</li>
        </ol>
      </div>
    } else if (score > 50 && score <= 80) {
      return <div className="result-text">
        <p style={{ margin: "5px auto" }} className="heading">Here are your next steps:</p>
        {/* <p>1. If you have not already done so, try using a free AI tool, like ChatGPT, for just 5 minutes to see its capabilities. Experiment with prompts related to your work, e.g. “Write a weekly newsletter excerpt in 100 words around my sermon script.” Paste your sermon script for the AI in the same prompt. See how it responds.</p>
        <p>2. Reach out to 5 church leaders using group text to ask if they use AI to increase their ministry impact. This way, you can recognize if churches around you are embracing AI and how.</p> */}
        {/* <p>1. Invite your leadership team to take this test.</p>
        <p>2. Unlock your team's individual responses and team scores*.</p>
        <p>3. Receive four practical tips about AI for your unique church context.</p> */}
        <ol>
          <li>Invite your leadership team to take this test.</li>
          <li>Unlock your team's individual responses and team scores*.</li>
        </ol>
      </div>
    } else if (score > 80) {
      return <div className="result-text">
        <p style={{ margin: "5px auto" }} className="heading">Here are your next steps:</p>
        {/* <p>1. If you have not already done so, try using a free AI tool, like ChatGPT, for just 5 minutes to see its capabilities. Experiment with prompts related to your work, e.g. “Write a weekly newsletter excerpt in 100 words around my sermon script.” Paste your sermon script for the AI in the same prompt. See how it responds.</p>
        <p>2. Reach out to 5 church leaders using group text to ask if they use AI to increase their ministry impact. This way, you can recognize if churches around you are embracing AI and how.</p> */}
        <ol>
          <li>Invite your leadership team to take this test.</li>
          <li>Unlock your team's individual responses and team scores*.</li>
        </ol>
        {/* <p>1. Invite your leadership team to take this test.</p>
        <p>2. Unlock your team's individual responses and team scores*.</p>
        <p>3. Receive four practical tips about AI for your unique church context.</p> */}
      </div>
    }

  };


  const showSampleDashboard = () => {
    return <>
      <div className="sample-dashboard-text">
        <div className="right-line"></div>
        <h1>Sample Dashboard</h1>
        <div className="left-line"></div>
      </div>
      <div className="individual-group">
        <button onClick={() => toggleTeamIndividual(1)} className={currentView == 0 ? "active left" : "left"}>TEAM</button>
        <button onClick={() => toggleTeamIndividual(0)} className={currentView == 1 ? "active right" : "right"}>INDIVIDUAL</button>
      </div>
      <div className="toggle-individual-group">
        <div className={currentView == 0 ? "active left" : "left"}>TEAM</div>
        <Switch
          checked={!toggleIndividual}
          onChange={toggleIndividualChange}
          inputProps={{ 'aria-label': 'controlled' }}
        />
        <div className={currentView == 1 ? "active right" : "right"}>INDIVIDUAL</div>
      </div>
      <div className="dashboard-report-img-container">
        {windowWidth > 550 ?
          <><img src={currentView == 0 ? sampleDashboardTeamImg : sampleDashboardIndividualImg} alt="sample team dashboard" srcset="" /></>
          :
          <><img src={currentView == 0 ? sampleDashboardTeamImgMobile : sampleDashboardIndividualImgMobile} alt="sample individual dashboard" srcset="" /></>
        }
      </div>
    </>
  }

  const showProgressBarText = (scoreValue) => {
    if (scoreValue >= 95) {
      return "Enjoying health";
    } else if (scoreValue >= 80 && scoreValue < 95) {
      return "Improving health";
    } else if (scoreValue >= 70 && scoreValue < 80) {
      return "Pursuing health";
    } else if (scoreValue < 70) {
      return "Battling unhealthiness";
    }
  }

  const showInvite = () => {
    if (isMember) return null;
    else if (props?.showInvite === false) return null;
    else return (<InviteInfo />)
  };

  const showTeamMember = () => {
    if (isMember) {
      return (
        <div className="team-container">
          <h3>Thank you for taking this assessment!</h3>
          Please get in touch with your leader for the next step.
          <br />
          <br />
        </div>
      );
    } else {
      return null;
    }
  };

  const showTestimonial = () => {
    if (!isMember) {
      return <Testimonial />;
    } else {
      // return null;
      return <Testimonial />;
    }
  };

  const showResources = () => {
    return <Resources />
  }

  const sendInvite = () => {
    return <SendInvite />
  }

  const getLeftMargin = () => {
    if (windowWidth < 651 && score > 97) {
      return { left: 98 + "%" };
    } else if (windowWidth < 651 && score < 3) {
      return { left: 2 + "%" };
    } else {
      return { left: Math.round(score) + "%" };
    }
    // return { left: Math.round(score) + "%" };
  };

  const getBarTextLeft = (score) => {
    if (window.innerWidth < 650 && score > 80) {
      return { left: "-170px" }
    }
    return { left: "-100px" }
  }

  const showSchedule = () => {
    if (isMember) {
      return null;
    } else {
      return (
        <div className="schedule-container">
          <div className="avatar">
            {/* <img src={scheduledImage} /> */}
          </div>
          <h3>Schedule a call with Kim</h3>
          <p>Ready to optimize your board for fundraising or craft and execute an effective annual giving or capital campaign focused on Biblical generosity?</p>
          <p>Talk with Kim to learn how your Christian school's fundraising can become truly transformative.</p>
          <div className="cta-btn">
            <a href="https://meetings.hubspot.com/kim-jennings" target="_blank">
              <Button>TALK TO KIM</Button>
            </a>
          </div>
        </div>
      );
    }
  };

  const showLegacyInvite = () => {
    if (isMember) {
      return null;
    } else {
      return (
        <div className="invite-legacy-container">
          <div className="invite-legacy">
            <h1>Invite your team members</h1>
            <p>Get your team to take this test and see if they think your church is guest ready.</p>
            <div className="cta-btn">
              <Link to={"/invite-team/" + id} target="_blank">
                <Button>Invite My Team</Button>
              </Link>
            </div>
          </div>
        </div>
      );
    }
  };

  const toggleTeamIndividual = (tab) => {
    if (tab == 1) {
      setCurrentView(0);
      setToggleIndividual(1);
    } else {
      setCurrentView(1);
      setToggleIndividual(0);
    }
  }

  const toggleIndividualChange = (e) => {
    // setToggleSection(!e.target.checked);
    if (e.target.checked) {
      toggleTeamIndividual(0);
    } else {
      toggleTeamIndividual(1)
    }
  }

  const handleCopyToClipboard = ( content ) => {
    // try {
    //   const input = textBoxRef.current;
  
    //   if (input) {
    //     input.select();
    //     document.execCommand("copy");
    //     input.setSelectionRange(0, 0);
    //     setIsCopied(true);
  
    //     if (navigator.clipboard) {
    //       await navigator.clipboard.writeText(input.value);
    //     }
  
    //     setTimeout(() => {
    //       setIsCopied(false);
    //     }, 1500);
    //   }
    // } catch (error) {
    //   console.error("Error copying text to clipboard:", error);
    // }

    const introText = "I am working to become someone who leads more prayerfully. \nOver the next month, I plan to ask God to help me as I focus on:\n";

    const outroText = "\nWould you please pray and check with me weekly how these are coming along?";

    const combinedText = introText + content.map((item, index) => `${index + 1}. ${item.question}`).join('\n') + outroText;

    navigator.clipboard.writeText(combinedText.trim());

  };

  const getAnswer = (questionId) => {
    const answer = answers.find(answer => answer.questionId === questionId);
   
    if (answer.answer === 1) {
      return "Strongly Disagree";
    }
    else if ( answer.answer === 2 ) {
      return "Disagree";
    }
    else if ( answer.answer === 3 ) {
      return "Neutral";
    }
    else if ( answer.answer === 4 ) {
      return "Agree";
    }
    return "Strongly Agree";
  }

  const handleRadioChange = (questionId, question) => {
    const isSelected = selectedQuestions.some((q) => q.id === questionId);
  
    if (isSelected) {
      setSelectedQuestions((prevSelected) =>
        prevSelected.filter((q) => q.id !== questionId)
      );
    } else {
      if (selectedQuestions.length < 2) {
        setSelectedQuestions((prevSelected) => [
          ...prevSelected,
          { id: questionId, question },
        ]);
      } else {
        setSelectedQuestions((prevSelected) => [
          ...prevSelected.slice(1),
          { id: questionId, question },
        ]);
      }
    }
  };
  

  const getFilteredQuestions = (bottomQuestions) => {
    const uniqueIds = []; // To keep track of unique question ids
    const filteredQuestions = selectedQuestions?.filter((selectedQ) => {
      if (bottomQuestions.some((bottomQ) => bottomQ.id === selectedQ.id)) {
        // If the question id is not in the array, add it and include the question in the result
        if (!uniqueIds.includes(selectedQ.id)) {
          uniqueIds.push(selectedQ.id);
          return true;
        }
      }
      return false;
    });
  
    console.log('filteredQuestions', filteredQuestions);
  
    return filteredQuestions;
  };
  


  if (isLoading) {
    return (
      <div className="container results loading">
        <CircularProgress style={{ color: '#7CCEE7' }} size={60} />
      </div>
    );
  }

  return (
    <>
      <div className="container results">
        <div className="details-container">

          {showTitleText()}


          <div className="progress-bar">
            <div className="si_ass_result" style={{ position: "relative" }}>
              <div className="progress-opacity-filter" style={{ width: "100%" }}>
                <div className="transparent" style={{ width: `${Math.round(score)}%`, height: "26px", background: `linear-gradient(90deg, #C2D7DD 0%, #7CCEE7 73%, #00A3D4 100%)`, borderRadius: Math.round(score) == 100 ? "10px" : "10px 0px 0px 10px", }}></div>
                <div className="opaque" style={{ width: `${(100 - Math.round(score))}%`, height: "26px", background: `linear-gradient(90deg, #C2D7DD 0%, #C2D7DD 73%, #C2D7DD 100%)`, borderRadius: Math.round(score) == 100 ? "10px" : Math.round(score) == 0 ? "10px" : "0px 10px 10px 0px", }}></div>
              </div>
              <div className="si_ass_progress">
                <div className="progress-line" style={{ left: Math.round(score) + '%' }}>
                  {/* <div className="progress-text" style={ windowWidth < 651 && score < 30 ? { left: '-50px', width: '10ch', top: '-85px' } : getBarTextLeft(score)}>{showProgressBarText(score)}</div> */}
                </div>
                <span className="msi_score" style={getLeftMargin()}>{Math.round(score)}%</span>
                <div className="score-arrow" style={{ left: Math.round(score) + '%' }}>
                  {/* <div className="progress-text" style={ windowWidth < 651 && score < 30 ? { left: '-50px', width: '10ch', top: '-85px' } : getBarTextLeft(score)}>{showProgressBarText(score)}</div> */}
                </div>
              </div>
              <div className="pervalue">
                <h4 className="progress-0-percent" style={{ minWidth: '0%' }}></h4>
                {/* <h4 className="progress-20-percent" style={{ minWidth: '20%' }}></h4> */}
                {/* <h4 className="progress-40-percent" style={{ minWidth: '20%' }}></h4> */}
                <h4 className="progress-50-percent" style={{ minWidth: '50%' }}></h4>
                {/* <h4 className="progress-60-percent" style={{ minWidth: '20%' }}></h4> */}
                <h4 className="progress-75-percent" style={{ minWidth: '25%' }}></h4>
                {/* <h4 className="progress-80-percent" style={{ minWidth: '30%' }}></h4> */}
                <h4 className="progress-100-percent" style={{ minWidth: '25%' }}></h4>
                {/* <h4 className="vexpe" style={{ minWidth: '5%' }}></h4> */}
              </div>


              <div className="si_pro_header">
                <h4 className="progress-0-percent" style={{ minWidth: '0%' }}></h4>
                {/* <h4 className="progress-20-percent" style={{ minWidth: '20%' }}></h4> */}
                {/* <h4 className="progress-40-percent" style={{ minWidth: '20%' }}></h4> */}
                <h4 className="progress-50-percent" style={{ minWidth: '50%' }}></h4>
                {/* <h4 className="progress-60-percent" style={{ minWidth: '20%' }}></h4> */}
                <h4 className="progress-75-percent" style={{ minWidth: '25%' }}></h4>
                {/* <h4 className="progress-80-percent" style={{ minWidth: '30%' }}></h4> */}
                <h4 className="progress-100-percent" style={{ minWidth: '25%' }}></h4>

                {/* <h4 className="eexpe1" style={{ minWidth: '30%' }}></h4> */}
                {/* <h4 className="eexpe" style={{ minWidth: '30%' }}></h4> */}
              </div>

              {/* <div className="pervalue-text"> */}
              {/* <h4 className="nimp" style={{ minWidth: '45%',  }}></h4> */}
              {/* <h4 className="mexpe" style={{ minWidth: '25%',  }}></h4> */}
              {/* <h4 className="eexpe" style={{ minWidth: '30%', f }}></h4> */}
              {/* <h4 className="vexpe" style={{ minWidth: '5%' }}></h4> */}
              {/* </div> */}

              <div className="pervalue-text">
                {
                  windowWidth >= 551 ?
                    <p className="not-aligned" style={{ width: "50%", fontWeight: score <= 50 ? 700 : 400 }}>Foundational<br />Practice</p>
                  :
                    <p className="not-aligned" style={{ width: "50%", fontWeight: score <= 50 ? 700 : 400 }}> Foundational<br />Practice</p>
                }
                {/* <p className="not-aligned" style={{ width: "20%", fontWeight: score > 20 && score <= 40 ? 700 : 400 }}>{windowWidth < 551 ? <span>Not Quite<br/>There</span> : <span>Not Quite There</span>}</p> */}

                <p className="some-aligned" style={{ width: "30%", fontWeight: score > 50 && score <= 80 ? 700 : 400 }}>{windowWidth <= 550 ? <span>Growing<br/>Practice</span> : <span>Growing<br/>Practice</span>}</p>
                <p className="some-aligned" style={{ width: "20%", fontWeight: score > 80 ? 700 : 400 }}>{windowWidth <= 551 ? <span>Transformational<br />Practice</span> : <span>Transformational<br />Practice</span>}</p>

                {/* <p className="high-aligned" style={{ width: "20%", fontWeight: score > 80 ? 700 : 400 }}>Superb!</p> */}
              </div>

            </div>
          </div>
          <div className="overall-score-subtext">
            <p>{name}, your overall score is <span>{Math.round(score)}%.</span><br />Below are your scores by category.</p>
          </div>

          <div className="score-section-container">
            {sectionsAndSubScores?.map((sec, idx) => {
              const lowestScore = sortedSectionsAndSubScores[0]?.section;
              let customColor = "#7CCEE7";
              let customTextStyles = { color: "#343333", fontWeight: 700 };
              if (sec.section === lowestScore && sec?.score < 100) {
                customColor = "#F08D6E  !important;";
                customTextStyles = {  fontWeight: 700 };
              }
              return (
                <div key={sec.section} className="score-section">
                  {/* <p className="score-txt">{Math.round(sec.score)}%</p> */}
                  <div className="score-slider">
                    {/* <LinearProgress className="custom-bar" variant="determinate" value={Math.round(sec?.score)}
                      sx={{
                        backgroundColor: customColor,
                        '& .MuiLinearProgress-bar': { backgroundColor: `${customColor} !important` },
                      }} /> */}
                    <CircularProgressWithLabel size={136} customcolor={customColor} className="custom-bar" variant="determinate" value={Math.round(sec?.score)} thickness={3} />
                  </div>
                  <p className="score-name" /* style={windowWidth>=831 ? customTextStyles : {}} */>{sec.section}</p>
                </div>)
            })}
          </div>
          {/* <div className="spacer"></div>
          <div className="spacer"></div> */}

          {/* <div className="charts-accordions-container">
            {chartsData?.map((chart, idx) => {
              return (<Accordion defaultExpanded={idx === 0 ? true : false}>
                <AccordionSummary expandIcon={<CustomExpandIcon />}>
                  <p>{chart?.title}</p>
                </AccordionSummary>
                <AccordionDetails>
                  <DonutChartAccordion answer={chart.answer} data={chart.values} order={chart.order} dashboard={false} />
                </AccordionDetails>
              </Accordion>);
              })
            }
          </div> */}

        </div>
      </div>

      {/* {
        !isMember ? <div className="result-text-container"> */}
        {/* {showScoreText()} */}

        {/* <div className="result-text">
          <p style={{ margin: "5px auto" }} className="heading">Here are your next steps:</p>
          <ol>
            <li>Invite your leadership team to take this test.</li>
            <li>Unlock your team's individual responses and team scores*.</li>
          </ol>
        </div> */}

        {/* <h3 className="pre-score-header">Here's how you scored on your 4 Ps:</h3> */}

        {/* <div className="btn-container">
          {!isMember && <div className="invite-cta-btn">
            <Link to={"/invite-team/" + id} target="_blank">
              <Button>INVITE MY TEAM</Button>
            </Link>
          </div>}
        </div>

        <div className="scroll-dashboard-tip">
          <p> *Scroll down to see what your dashboard may look like.</p>
        </div>
      </div> : <></>
      } */}

      {/* {showLegacyInvite()} */}




      {/* /new code */}

      {sections?.map((el, index) => {
        const asciiCode = 65 + index;
        const character = String.fromCharCode(asciiCode);
        const showAccountabilitySection = Math.round(sectionsAndSubScores?.[index]?.score) <= 35;
        console.log("el",el)
        return (
          <div className="charts-accordions-container" key={index}>
            <Accordion className="accordion-container" defaultExpanded={index === 0 ? true : false}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className="result-typography">
                  {`PART ${character}: ${el.section}: ${Math.round(sectionsAndSubScores?.[index]?.score)}%`}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="accordion-details-container">
                  {el?.topQuestions?.length > 0 && 
                  <div className="result-container">
                    <div className="header">
                      <div>Areas to Celebrate</div>
                    </div>
                    <div className="description">
                      <p>First, let's celebrate the ways you already rely on the Lord through prayer in your daily life!</p>
                    </div>
                    <div className="content" >
                      {
                        el?.topQuestions?.map((tq, idx) => {
                          return <div className="item" key={idx}>
                            <div className="item-description" style={{ padding: showAccountabilitySection ? "10px 10px 10px 45px" : "10px 10px 10px 10px" }}>
                              {tq.question}
                            </div>
                            <div className="item-status">
                              {/* {getAnswer(tq.id)} */}
                              {tq?.answerText?.trim()?.replace(/\s+/g, " ")}
                            </div>
                          </div>
                        })
                      }
                    </div>
                  </div>}
                  {el?.bottomQuestions?.length > 0 &&
                  <div className="result-container result-container-second">
                    <div className="header">
                      <div>AREAS TO Improve</div>
                    </div>
                    <div className="description">
                      <p>
                        Here are some areas where your answers differed from those of world-changing leaders.
                      </p>
                      {showAccountabilitySection && <h4>Choose two areas below to ask God to work in during the upcoming month.</h4>}
                    </div>
                    <div className="content">
                      {el?.bottomQuestions?.map((question, idx) => {
                        const checked = false;
                        return <div className="item" key={idx} 
                        style={{ cursor: showAccountabilitySection ? "pointer" : "auto" }}
                        onClick={() => {
                          if (showAccountabilitySection) {
                            handleRadioChange(question.id, question.question);
                          }}}>
                          <div className="radio-question-container">
                            {showAccountabilitySection && <input type="radio"
                              onClick={() => {
                                // handleRadioChange(question.id, question.question)
                              }}
                              onChange={() => {}}
                              checked={selectedQuestions.some((q) => q.id === question.id)}
                            />}
                            <div className="item-description">{question.question}</div>
                          </div>
                          <div className="item-status">
                            {/* {getAnswer(question.id)} */}
                            {question?.answerText?.trim()?.replace(/\s+/g, " ")}
                          </div>
                        </div>
                      })}
                        {console.log("selectedQuestions",selectedQuestions)}
                    </div>
                  </div>}
                  {showAccountabilitySection && 
                    <div className="result-container result-container-third">
                      <div className="header">
                        <div>ACCOUNTABILITY</div>
                      </div>
                      <div className="accountability-content">
                        <div className="description">
                          <p>Setting goals is a great first step! To help with your follow-through, add some accountability! In one study, 33 percent more people achieved their goals when they shared them with someone else. Accountability is crucial.</p>
                          <p>To dramatically increase the likelihood that you'll act on the changes you hope to make, copy, paste and edit the message below and email or text it to someone who will hold you accountable.</p>
                        
                        </div>
                        <div className="content">
                          <div className="content-items">
                            <div className="clipboard-txt-top">
                              <p>“I am working to become someone who leads more prayerfully.</p>
                              <p>Over the next month, I plan to ask God to help me as I focus on:</p>
                            </div>
                            <div className="clipboard-questions-txt">
                              {
                                getFilteredQuestions(el?.bottomQuestions)?.map((selectedQ, index) => {
                                  return <p key={index}>{index+1}. {selectedQ.question}</p>
                                })
                              }
                            </div>
                            <div className="clipboard-txt-bottom">Would you please pray and check with me weekly how these are coming along?”</div>
                          </div>
                        </div>
                        <div className="copy-clipboard" 
                          onClick={() => handleCopyToClipboard(getFilteredQuestions(el?.bottomQuestions))}>
                          <Button>
                            <img src={CopyImg} alt="copy" />Copy Message
                          </Button>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        );
      })}

      <div className="result-important-notice">
        <img src={ImportantImg} alt="important"/>
        <div className="result-important-notice-txt">
          The research in <span>Lead with Prayer </span> revealed that becoming a praying leader is a journey. These results are designed to help you progress through the four phases of prayer, step by step. If you'd like to view the rest of your results, you may, but we strongly encourage you not to act on Part B until you're thriving in Part A, and to wait to tackle Part D before mastering Part C.
        </div>
      </div>


      {/* {
        sections.map((el,index)=>{
          const asciiCode = 65 + index;
          const character = String.fromCharCode(asciiCode);
          return <div className="charts-accordions-container">  
            <Accordion className="accordion-container" defaultExpanded={true}  >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className="result-typography" PART {character}: {el.section} : {el.score}%</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="accordion-details-container">
                  <div className="result-container">
                    <div className="header">
                      <div>areas to Celebrate</div>
                    </div>
                    <div className="description">
                      <p>  
                        First, let's celebrate the ways you already rely on the Lord through prayer in your daily life!
                      </p>
                    </div>
                    <div className="content">
                      <div className="item">
                        <div className="item-description">
                          I schedule significant daily time with God.
                        </div>
                        <div className="item-status">
                          Strongly Agree
                        </div>
                      </div>
                      <div className="item" style={{ background: "white" }}>
                        <div className="item-description">
                          Honestly, I tend to see prayer as a waste of time.
                        </div>
                        <div className="item-status">
                          Strongly Disagree
                        </div>
                      </div>
                      <div className="item" style={{borderRadius:"0px 0px 9px 9px"}}>
                        <div className="item-description">
                          When I start to pray, it feels more like an obligation than a delight.
                        </div>
                        <div className="item-status">
                          Strongly Disagree
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="result-container result-container-second">
                    <div className="header">
                      <div>AREAS TO Improve</div>
                    </div>
                    <div className="description">
                      <p>
                        Here are some areas where your answers differed from those of world-changing leaders.
                      </p>
                      <h1>
                        Choose two areas below to ask God to work in during the upcoming month.
                      </h1>
                    </div>
                    <div className="content">
                      <div className="item">
                        <div className="wrapper"> 
                        <input type="radio" />
                        <div className="item-description">
                          I regularly retreat for a day or more to create space for intentionally relying on God rather than my own effort.
                          </div>
                        </div>
                        <div className="item-status">
                          Disagree
                        </div>
                      </div>
                      <div className="item" style={{ background: "white", height:"50px" }}>
                        <div className="wrapper">

                        <input type="radio" />

                        <div className="item-description" style={{height:"25px"}}>
                          When making decisions, I tend to be more Spirit-reliant than self-reliant.
                        </div>
                        </div>
                        <div className="item-status">
                        Neutral
                        </div>
                      </div>
                      <div className="item" style={{ borderRadius: "0px 0px 9px 9px", background:"#F0F0F0", height:"50px" }}>
                        <div className="wrapper">

                        <input type="radio" />

                        <div className="item-description" style={{height:"25px"}}>
                          During my workday, prayer is my first priority (not my last resort).
                        </div>
                        </div>
                        <div className="item-status">
                        Disagree
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="result-container result-container-third">
                    <div className="header">
                      <div>ACCOUNTABILITY</div>
                    </div>
                    <div className="description">
                      <p>
                        Setting goals is a great first step! To help with your follow-through, add some accountability! In one study, 33% more people achieved their goals when they shared them with someone else. Accountability is crucial.
                      </p>
                      <p className="bold">
                        Double the likelihood of accomplishing your new goals for growing in prayer.
                        <span>
                          Copy and paste the message below and email or text it to someone who will hold you accountable.

                        </span>

                      </p>
                    
                    </div>
                    <div className="content">
                      <div className="content-items">“I am working to become someone who leads more prayerfully.
                        <br />Over the next month, I plan to ask God to help me as I focus on:
                        <br />1. Regularly retreating for a day or more to create space for intentionally relying on God rather than my own effort.
                        <br />2. During my workday, making prayer my first priority (not my last resort).
                        <br />Would you please pray and check with me weekly how these are coming along?”</div>
                      
                    </div>
                    <div className="copy-clipboard">
                      <img src={CopyImg} alt="copy" />
                      <div>Copy this message to my clipboard.</div>
                    </div>
                  </div>
                  <div className="result-important-notice">
                    <img src={ImportantImg} alt="important"/>
                    <div className="result-important-notice-txt">
                      The research in <span>Lead with Prayer </span> revealed that becoming a praying leader is a journey. These results are designed to help you progress through the four phases of prayer, step by step. If you'd like to view the rest of your results, you may, but we strongly encourage you not to act on Part B until you're thriving in Part A, and to wait to tackle Part D before mastering Part C.
                    </div>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        })
      } */}
      
      <div className="footer-about">
        
        <p>Becoming a praying leader takes time. We recommend that you take this assessment periodically to check your growth and reflect on how you are progressing in your practice of personal and organizational prayer.</p>

      </div>

      {/* <Accordion className="accordion-container" defaultExpanded={index === 0 ? true : false}>
        <AccordionSummary className="accordion-summary" expandIcon={<ExpandMore className="accordion-expand-icon" />} aria-controls="panel1a-content">
          <p className="accordion-title">{data.section}</p>
        </AccordionSummary>
        <AccordionDetails className="accordion-content">
         
        </AccordionDetails>
      </Accordion> */}

      {/* {!isMember && showSampleDashboard()} */}

      {/* {showTeamMember()} */}

      {/* {showLegacyInvite()} */}



      {/* {showInvite()} */}

      {sendInvite()}

      <Promo/>

      { showResultsFooter() }

      {/* {showTestimonial()} */}

      {/* {showResources()} */}


      {/* <Testimonial /> */}


      {/* <div className="testimonial-container">

        <div className="testimonial">

          <div className="testi">
            <span className="quotation-mark">“</span>Doing this assessment as a team gave us better insights into our level of alignment with our school board. It also helped us put a plan in place to make our meetings more productive, so that we continue to align on the board's objectives together.<span className="quotation-mark bottom"><div className="quotation-block">“</div></span>
          </div>

          <div className="avatar">
            <img src={testimonialImage} />
          </div>

          <div className="designation">
            Sam Davis<br />
            <span>Lead Pastor, Springs of Life Church</span>
          </div>

        </div>

      </div> */}

      {/* {showSchedule()} */}

      {/* {showLegacyInvite()} */}

      {/* </div> */}

    </>

  );
};

export default Results;


function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative' }}>
      <CircularProgress variant="determinate"
        sx={{ color: "#E9E9E9 !important", position: 'absolute', }}
        size={40} thickness={4} {...props} value={100} />
      <CircularProgress variant="indeterminate" {...props}
        value={-props.value} // to show anticlockwise progress 
        sx={{
          '&': { color: `${props.customcolor} !important`, zIndex: 1, },
          '&.MuiCircularProgress-root': {
            strokeLinecap: 'round',
          },
        }} />
      <Box sx={{
        top: 0, left: 0, bottom: 0, right: 0, position: 'absolute',
        display: 'flex', alignItems: 'center', justifyContent: 'center',
      }}>
        <p variant="caption" component="div" color="text.primary" style={{ fontSize: "24px", fontWeight: "700", lineHeight: "30px", margin: "0 auto" }}>
          {`${Math.round(props.value)}%`}
        </p>
      </Box>
    </Box>
  );
}

