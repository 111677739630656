import Button from "@mui/material/Button";
import prevArrow from '../Assets/Images/prev-arrow.svg';
import nextArrow from '../Assets/Images/next-arrow.svg';
import DonutChart from "../Components/DonutChart";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import useWindowDimensions from "../Hooks/useWindowDimensions";
import {useEffect, useState} from "react";
import { CircularProgress } from '@mui/material';

const Charts = () => {

  const { id, pageId, qNum }  = useParams();
  const navigate        = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState([]); 
  const [answer, setAnswer] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  


	
  useEffect(() => {

    // setIsLoading(true);
		
    fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment/' + id + '/pages/' + pageId, {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' }
    }).then(response => {
      return response.json();
    }).then(data => {
      if(data.status == "success") {
        const values = data.data.chartValues;
        const answer = data.data.selectedAnswer;        
        const ans = values?.find((val) => val.name === answer);
        setAnswer(ans);
        setData(values);
        setIsLoading(false);
        return;
      } else {
        console.log("SOMETHING WENT WRONG");
        return;
      }
    }).catch((err) => console.error("Error while fetching form questions...", err));
	},[]);
	
  // console.log(answer);
  // console.log(answer,data);

  const prevPage = () => {
    if(pageId && (pageId > 28)) {
      localStorage.removeItem("goingForward");
      localStorage.setItem("goingBackward", true);
      navigate("/assessment/" + id + "/" + (parseInt(pageId) - 1) + "/?" + searchParams);
    }
  }
  
  const nextPage = () => {
    if(pageId && (pageId < 38)) {
      localStorage.removeItem("goingBackward");
      localStorage.setItem("goingForward", true);
      navigate("/assessment/" + id + "/" + (parseInt(pageId) + 1) + "/?" + searchParams);
    }
  }
  
  const{windowWidth} = useWindowDimensions();

  if (isLoading) {
		return <div className="container assessment loading"><CircularProgress style={{ color: '#ED2028' }} size={60} /></div>
	}

  // page id 29
  //page id 31
  //page id 33
  //page id 35
  //page id 37

  console.log("VALS", pageId,answer);

  const neutralText = (ans) => {
    if(ans.toLowerCase() === "neutral") return "feel neutral about";
    else return ans.toLowerCase() + " that";
  }
  
  return (
    <div className="chart-container">
      
      {pageId == 29 && <div className="chart-heading">
        <h1 className="exception"><span>{answer.y}% of similarly-sized churches</span> also {neutralText(answer.name.toLowerCase())}</h1>
          <p>“The community around our church is aware of our mission.”</p>
      </div>}

      {pageId == 31 && <div className="chart-heading">
        <h1><span>{answer.y}% of similarly-sized churches</span> also hosted {answer.name.toLowerCase()} engagement opportunities in the past year.</h1>
          {/* <p>“Our church facilities and programs are frequent fixtures in our community’s calendar.”</p> */}
      </div>}

      {pageId == 33 && <div className="chart-heading">
        <h1 className="exception"><span>{answer.y}% of similarly-sized churches</span> also had {answer.name.toLowerCase()} community members participate in engagement opportunities.</h1>
          {/* <p>“Our church facilities and programs are frequent fixtures in our community’s calendar.”</p> */}
      </div>}

      {pageId == 35 && <div className="chart-heading">
        <h1><span>{answer.y}% of similarly-sized churches</span> also agreed that their community engagement was {answer.name.toLowerCase()}.</h1>
          {/* <p>“Our church facilities and programs are frequent fixtures in our community’s calendar.”</p> */}
      </div>}

      {pageId == 37 && <div className="chart-heading">
        <h1 className="exception"><span>{answer.y}% of similarly-sized churches</span> also {neutralText(answer.name.toLowerCase())}</h1>
          <p>“Our community frequently utilizes our church's building facilities and programs.”</p>
      </div>}

      <div className="chart-heading">
        {/* <h1><span>{answer.y}% of similarly-sized churches</span> also {answer.name} that</h1> */}
          {/* <p>“The community around us is aware of our mission.”</p> */}
      </div>
    <div className="chart-data">
      <DonutChart answer={answer} data={data}/>
    </div>

     {windowWidth > 830 ? <div className="step-buttons">
        <Button className="cta-prev" onClick={() => prevPage()}>
          <img src={prevArrow} alt="Previous Page Arrow" />&nbsp;&nbsp;PREV
        </Button>
        <Button className="cta" onClick={() => nextPage()}><span className="next-btn">NEXT</span>&nbsp;&nbsp;<img src={nextArrow} alt="Previous Page Arrow" /></Button>
      </div>
     :  <div className="step-buttons">
    <Button className="cta-prev" onClick={() => prevPage()}>
      <img src={prevArrow} alt="Previous Page Arrow" />&nbsp;&nbsp;
    </Button>
    <Button className="cta" onClick={() => nextPage()}><span className="next-btn">NEXT</span></Button>
  </div>}
  </div>
    )
}

export default Charts;