import React, { useRef , useState} from 'react'
import logo from '../../Assets/Images/echo-logo.svg'
import mobile from '../../Assets/Images/promo-mobile-img.png'
import playBtn from '../../Assets/Images/play-btn.svg';
import playBtnHovered from '../../Assets/Images/play-btn-hover.svg';
import useWindowDimensions from '../../Hooks/useWindowDimensions';
import { useSearchParams } from 'react-router-dom';
import { Button } from '@mui/material';

export default function Promo() {

  const [searchParams, setSearchParams] = useSearchParams();

  const [isPlayingVideo, setIsPlayingVideo] = useState(false);
  const [isVidPaused, setIsVidPaused] = useState(false);
  const [btnHovered, setBtnHovered] = useState(false);
  // const [startPlaying, setStartPlaying]   = useState(false);
  const [currentTime, setCurrentTime] = useState(0);

  const [isSeeking, setIsSeeking] = useState(false);
  
  const { windowWidth } = useWindowDimensions();

  const vidRef = useRef(null);
  const introElmRef = useRef(null);
  const videoElmRef = useRef(null);

  const playVideo = (e) => {

    if(windowWidth > 830) {
      const introElm = introElmRef.current;
      const videoElm = videoElmRef.current;

      introElm.style.cssText = "width: 0%; padding: 0; overflow: hidden; max-height: 520px;";
      videoElm.style.cssText = "width: 100%; margin: 0 auto; background-color: #DFF8FF; border-radius: 10px;";
      // if(display === "preview") {
      //   videoElm.style.background = `#242424 url(${introBgImg}) repeat`;
      // } else videoElm.style.backgroundColor = "#435B63";
    }

    vidRef.current.play();
    setIsPlayingVideo(true);
    setIsVidPaused(false);
    // if(!startPlaying) {
    //   setStartPlaying(true);
    // }
  }
  
  const showPlayButton = () => {
    if (isPlayingVideo) {
      return null
    } else {
      return <div className="play-btn-img" onClick={(e) => playVideo(e)} onMouseEnter={() => setBtnHovered(true)} onMouseLeave={() => setBtnHovered(false)} >
        <a href="https://youtu.be/HpTTXK_ARxw" target="_blank" rel="noreferrer">Watch the video</a>
      </div>
    }
  }

  return (
    <div className='promo-container'>
      <div className="promo-container-heading">
        <h1>How would you like to pray without ceasing?</h1>
      </div>
      <div className="promo-subtext-logo-container">
        <div className="promo-logo">
          <p className="logo-text">Try</p>
          <img src={logo} alt="" />
        </div>
        <div className="promo-text">a powerful tool equipping leaders to engage with God in prayer</div>
      </div>
      <div className="promo-content-container">
        <div className="promo-mobile-image-container">
          <img src={mobile} alt="" />
        </div>
        <div className="promo-mobile-text-content-container">
          <div className="promo-text">
            <p>Echo helps prayer become routine so you can stay in conversation with God throughout the day.</p>
            <p>Explore guided prayers, create groups, share requests, set reminders, and keep your lists in one place.</p>
            <p>Echo is the perfect tool for leaders to add substance to their prayer lives.</p>
          </div>
          <div className="promo-watch-video-btn">
            {showPlayButton()}
          </div>
        </div>
      </div>
    </div>
  )
}
